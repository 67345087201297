import "./App.css";
import SurveyList from "./SurveyList";
import Screener from "./Survey/SurveyScreens/SurveyScreener";
import DynamicSurvey from "./Survey/Surveys";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from "./home/home";
import SurveyLanding from "./home/SurveyLanding.js";
import TakeSurvey from "./Survey/TakeSurvey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import footerlogo from "./images/footer-logo.png";
import background from "./bgimg/nelson-background.png";
class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <div className="content"  >
            <Switch>
              <Route exact path={"/"} component={SurveyLanding} />
              <div style={{ backgroundColor: "#fff" }}>
                <Route exact path={"/thank-you"} component={TakeSurvey} />
                <Route exact path="/survey-list" component={SurveyList} />
                <Route path="/login" component={SurveyList} />
                <Route exact path={"/screener"} component={Screener} />
                <Route exact path={"/surveys/:ids"} component={DynamicSurvey} />
              </div>

            </Switch>
          </div>

          <footer style={{ backgroundColor: "#0A3879" }} class="footer-wrapper">
            <div class="container-fluid">
              <div class="row align-items-center">
                <div class=" col-md-4 col-6 logo-add">
                  <img src={footerlogo} />

                </div>
                <div class=" col-md-4 col-12 logo-text">

                  <p class="text-white mb-md-0">
                    7301 Topanga Canyon Blvd, Suite 310 Canoga Park, CA
                    91303
                  </p>
                </div>
                <div class="col-md-4 col-6 social-icons">
                  <a href="https://www.facebook.com/nationwidemarketresearchgroups/" target="_blank"><FontAwesomeIcon 
                    icon={faFacebook} 
                    color="white"
                  /></a>
                  <a href="https://twitter.com/nelsnrecruiting" target="_blank"><FontAwesomeIcon
                    icon={faTwitter}
                    color="white"
                    style={{ marginLeft: 10 }}
                  /></a>
                  <a href="https://www.linkedin.com/company/nelsonrecruiting-marketresearch/" target="_blank"><FontAwesomeIcon
                    icon={faLinkedin}
                    color="white"
                    style={{ marginLeft: 10 }}
                  /></a>
                  <a href="https://www.instagram.com/nelsonrecruiting" target="_blank"><FontAwesomeIcon
                    icon={faInstagram}
                    color="white"
                    style={{ marginLeft: 10 }}
                  /></a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
