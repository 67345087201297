import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import Webcam from "react-webcam";
import _ from "lodash";
import socketIOClient from "socket.io-client";
import { Link } from "react-router-dom";
import "../index.css";
import logo from "../images/logo.png";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../../node_modules/startbootstrap-simple-sidebar/css/simple-sidebar.css";
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";
import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import "pretty-checkbox/dist/pretty-checkbox.css";

import VideoCap from "../VideoCapture";
import Speech from "speak-tts";
import { useParams } from "react-router-dom";
import Axios from "axios";
import ReactPlayer from "react-player";

widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);

widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);
const videoConstraints = {
  width: 400,
  facingMode: "user",
  audio: true
};
var model = {};
export const SocketProvider = React.createContext();
export const socket = socketIOClient("https://nr.dealopia.com");
const SurveyScreener = (props) => {
  const speech = new Speech();
  const [refresh, setRefrsh] = useState(true);
  const [jsonData, setJsonData] = useState([]);
  const [videoImage, setVideoImage] = useState("");
  let { ids } = useParams();
  const [isNdaSigned, setIsNdaSigned] = useState(false);
  const [isNdaUrl, setIsNdaUrl] = useState("");
  const [questionWithValue, setQuestionWithValue] = useState([]);
  const [surveyQuota, setSurveyQuota] = useState([]);
  const [quotaIndex, setQuotaIndex] = useState([]);
  const [quotaIndexArr, setQuotaIndexArr] = useState([]);
  const [videoQuestionName, setVideoQuestionName] = useState("");
  const [pagesList, setPagesList] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [status, setStatus] = useState("active");
  const [allow, setAllow] = useState(false);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [counter, setCounter] = useState(2);
  const [counter1, setCounter1] = useState(1);
  const [conditionTriggerState, setConditionTriggerState] = useState(false);
  const [conditionTriggerStateV, setConditionTriggerStateV] = useState(false);
  const [completeStatus, setCompleteStatus] = useState(false);
  const [completeResult, setCompleteResult] = useState([]);
  const [visibleArr, setVisibleArr] = useState([]);
  const [unVisibleList,setUnVisibleList] = useState("no");
  const [ipData,setIpData] = useState({});
  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    if (!_.isNull(mediaRecorderRef.current)) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, webcamRef, setCapturing]);


  // generating random string
  function generateRandomString() {
    if (window.crypto) {
      var a = window.crypto.getRandomValues(new Uint32Array(3)),
        token = '';
      for (var i = 0, l = a.length; i < l; i++) token += a[i].toString(36);
      return token;
    } else {
      return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
  }
  useEffect(
    () => {
      Axios.get(`https://geoip-db.com/json/`).then(res=>{
        console.log(res);
        setIpData(res.data);
      }).catch(err=>console.log(err))
    },[]
  )
  useEffect(() => {
    socket.emit("getSurveyById", { ids });
  }, [ids]);
  useMemo(() => {
    socket.on("listSurvey", (data) => {
      let quota = JSON.parse(data).quotajson;
      if (quota) {
        setSurveyQuota(JSON.parse(quota));

      }
    });
    return () => socket.off("listSurvey");
  }, []);



  useEffect(() => {
    var params = new URLSearchParams(props.location.search);
    var env = params.get("env");
    Axios.get(`/api/getSurvey?surveyId=${ids}`)
      .then((res) => {
        console.log(res.data);
        setJsonData(res.data);
        if (res.data.RequestedNda && !env) {
          setIsNdaSigned(res.data.RequestedNda);
          Axios.get("/api/getNDAList")
            .then((resNda) => {
              let details = resNda.data.details;
              let powerFormData = _.find(
                details,
                (dt) =>
                  dt.templateName === res.data.SelectNda ||
                  dt.templateName === res.data.SelectNda
              );
              if (powerFormData) {
                setIsNdaUrl(powerFormData.powerFormUrl);
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }, [ids]);
  useMemo(() => {
    if (jsonData) {
      model = new Survey.Model(jsonData);
    }
  }, [jsonData]);
  const handleRedirection = (e) => {
    e.preventDefault();
    localStorage.setItem("surveyId", ids);
    window.location.assign(isNdaUrl);
  };

  useEffect(() => {
    if (jsonData) {
      if (jsonData.pages) {
        if (jsonData.pages[0]) {
          setPagesList(jsonData.pages[0]);
          const questions = jsonData.pages[0].elements;
          const questionsName = questions.map((q) => q.name);
          setVideoQuestionName(questionsName ? questionsName[0] : null);
        }
      }
    }
  }, [jsonData]);
  useEffect(() => {
    const questions = pagesList ? pagesList.elements : null;

    setVideoQuestionName(questions ? questions[0].name : null);
  }, [pagesList]);
  useEffect(() => {
    if (jsonData) {
      let d = _.map(jsonData.pages, (vts, i) => {
        console.log("vts")

        if (vts.elements[0].hasOwnProperty('visibleIf')) {
          return {
            name: vts.elements[0].name,
            visibleIf: vts.elements[0].visibleIf
          }
        } else {
          return null
        }
      });

      setVisibleArr(_.without(d, null));
      console.log(_.without(d, null))
    }

  }, [jsonData])
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const env = params.get("env");
    if (env) {
      setIsNdaSigned(false);
    }
  }, []);

  const constraints = {
    audio: false,
    video: { width: 1280, height: 720 },
  };
  if (!navigator.mediaDevices) {
    setAllow(false);
    //  console.log("Document not secure. Unable to capture WebCam.");
  } else {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        //show webcam stream
        setAllow(true)
      })
      .catch(function (err) {
        setAllow(false)
        // console.log("Unable to capture WebCam.", err);
      });
  }
  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const surveyValidateQuestionWithQuota = (survey, options) => {

    var Question = _.keys(options.data);
    var questionVal = options.data[Question];

    var quotaNewArr = surveyQuota ? _.map(surveyQuota, (sn, i) => {
      return sn.logicexpression ? sn.logicexpression[0] : sn.logicexpression
    }) : null;
    let quotaArr = quotaNewArr ? _.map(quotaNewArr, (sl, j) => {
      if (sl) {
        if (sl.hasOwnProperty('questionValue')) {
          if (
            (sl.questionValue.name === Question[0] &&
              sl.inputAnswer === questionVal) ||
            (sl.questionValue.name === Question[0] && sl.answer === questionVal) || (sl.questionValue.name === Question[0] && sl.answer === questionVal[0])
          ) {

            setQuotaIndex((quotaIndex) => [...quotaIndex, j]);
            setQuotaIndexArr(surveyQuota[j]);

            return surveyQuota[j];
          }
        }
      }

    })
      : null;



    let a = _.map(quotaArr, (sq) => _.without(sq, undefined));
    let n = _.without(quotaArr, undefined);
    var quotaFiltered = n.filter((ele) => {
      return ele.constructor !== Object && Object.keys(ele).length > 0;
    });

    if (!_.isNull(quotaArr) && !_.isEmpty(quotaArr) && quotaArr && n) {
    
      let c = n[0];
      let b = quotaArr[0];
      if (b) {
        setUnVisibleList("yes");
        if ((parseInt(b.quota) > parseInt(b.responseCount))) {
          options.complete();
          return;
        } else {

          setStatus("inactive");
          // options.errors[Question] = "The quota have been fulled";
          //tell survey that we are done with the server validation
          options.complete();
          return;
        }
      } else if (c) {
        setUnVisibleList("yes");
        if ((parseInt(c.quota) > parseInt(c.responseCount))) {
          options.complete();
          return;
        } else {

          setStatus("inactive");
          // options.errors[Question] = "The quota have been fulled";
          //tell survey that we are done with the server validation
          options.complete();
          return;
        }

      } else {
        options.complete();
        return;
      }
    } else {
      options.complete();
      return;
    }
  };

  const onValueChanged = (sender, result) => {
    if (!capturing && allow) {
      handleStartCaptureClick();
    }
    setCompleteStatus(false)
    var Question = result.name;
    var questionVal = result.value;

    var quotaNewArr = surveyQuota ? _.map(surveyQuota, (sn, i) => {
      return sn.logicexpression ? sn.logicexpression[0] : sn.logicexpression
    }) : null;
    // Shows all indexes, not just those with assigned values
    quotaNewArr.find(function (questionD, index) {
      if (questionD.hasOwnProperty('questionValue')) {
        if (questionD.questionValue.name === Question) {

          if ((questionD.questionValue.name === Question &&
            questionD.inputAnswer === questionVal) ||
            (questionD.questionValue.name === Question && questionD.answer === questionVal || questionD.questionValue.name === Question && questionD.answer === questionVal[0])) {

            setQuotaIndex((quotaIndex) => [...quotaIndex, index]);
          } else {

            removeItemOnce(quotaIndex, index);
          }
        }
      }
    });
  };
  var videoImages = [
    {
      name: "Brown Asian Latino Young Female",
      value: "brown-asian-latino-young-female",
    },
    {
      name: "Brown Asian Latino Young Male",
      value: "brown-asian-latino-young-male",
    },
    {
      name: "White Middle Aged Female",
      value: "white-middle-aged-female",
    },
    {
      name: "White Middle Aged Male",
      value: "white-middle-aged-male",
    },
    {
      name: "White Young Female",
      value: "white-young-female",
    },
    {
      name: "White Young Male",
      value: "white-young-male",
    },
    {
      name: "African Middle Aged Female",
      value: "african-middle-aged-female",
    },
    {
      name: "African Middle Aged Male",
      value: "african-middle-aged-male",
    },
    {
      name: "African Young Female",
      value: "african-young-female",
    },
    {
      name: "African Young Male",
      value: "african-young-male",
    },
    {
      name: "Brown Asian Latino Middle Aged Female",
      value: "brown-asian-latino-middle-aged-female",
    },
    {
      name: "Brown Asian Latino Middle Aged Male",
      value: "brown-asian-latino-middle-aged-male",
    },
  ];
  const voiceValue = ["brown-asian-latino-young-female", "brown-asian-latino-young-male", "white-middle-aged-female", "white-middle-aged-male", "white-young-female", "white-young-male", "african-middle-aged-female", "african-middle-aged-male", "african-young-female", "african-young-male", "brown-asian-latino-middle-aged-female", "brown-asian-latino-middle-aged-male"];
  const random = Math.floor(Math.random() * voiceValue.length);
  useEffect(
    () => {
      setVideoImage(voiceValue[random]);
    }, []
  )
  const onCurrentPageChanging = (survey, options) => {
    let currentPageName = options.newCurrentPage.propertyHash.name;
    let currentPageNo = _.replace(currentPageName, 'page', '');
    setVideoQuestionName(`question${currentPageNo}`);
    let prvInd = jsonData.pages.indexOf(pagesList);
    if (options.isNextPage === false && options.isPrevPage === false) {
    } else if (options.isNextPage) {
      setCounter(counter + 1);
      setCounter1(counter1 + 1);
    } else {
      setPagesList(jsonData.pages[prvInd - 1]);
    }
  };


  useEffect(() => {
    Object.size = function (obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    };

    var size = Object.size(completeResult);
    if (size) {
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4'
      });
      const url = URL.createObjectURL(blob);
      // getting unique identifier for the file name
      var fileName = generateRandomString() + '.mp4';
      var file;
      if (recordedChunks.length && allow) {
        file = new File([blob], fileName, {
          type: 'video/mp4'
        });
      } else {
        file = null;
      }
      const formData = new FormData();
      formData.append("dataFile", file);
      formData.append("postId", ids);
      formData.append("surveyResult", JSON.stringify(completeResult));
      formData.append("status", status);
      formData.append("unmatched", unVisibleList);
      formData.append("ipaddress",ipData ? ipData.IPv4 : null);
      Axios.post(`/api/post/`, formData)
        .then((res) => {
          socket.emit("getSurveyById", { ids });
          window.open('https://s2.focuspocussoftware.com/WP_NR/Register');
        })
        .catch((err) => console.log(err));
    }
  }, [completeResult, recordedChunks])


  const onComplete = (result) => {
    setVideoUrl("");
    socket.emit("updateResponseCount", {
      surveyId: ids,
      quotaIndex: quotaIndex,
    });
    handleStopCaptureClick();
    setTimeout(() => {
      setCompleteResult(result.data);
    }, 3000);
  };
  useEffect(() => {
    setVideoUrl(
      `https://nelson-survey-lipsync.s3.us-east-2.amazonaws.com/videos/${ids}/vid-${ids}-${videoQuestionName}-${videoImage}.mp4`
    );
  }, [ids, videoQuestionName, videoImage]);
  return (
    <SocketProvider.Provider value={{ socket }}>
      <div>
        <Navbar
          className="shadow p-md-3 mb-md-3 bg-white rounded"
          style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
        >
          <div className="container-fluid">
            <Navbar.Brand href="#home">
              <Image src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto"></Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>

        <div className="container body-wrapper" >
          {!isNdaSigned && (
            <Fragment>
              {videoImage ? (
                <Fragment>
                  <div className="form-wrapper survey-screener" style={{ backgroundColor: "#fff" }}>
                    <Survey.Survey
                      model={model}
                      onComplete={onComplete}
                      onValueChanged={onValueChanged}
                      onServerValidateQuestions={
                        surveyValidateQuestionWithQuota
                      }
                      onCurrentPageChanging={onCurrentPageChanging}
                    />
                  </div>
                  <div className="camraScreen">
                    <Webcam audio={true} ref={webcamRef} videoConstraints={videoConstraints} />
                  </div>

                </Fragment>
              ) : (
                <Fragment>
                  <div
                    className="form-wrapper survey-screener"
                    style={{ display: "block", backgroundColor: "#fff" }}
                  >
                    <p>
                      <strong>Please Identify Yourself</strong>
                    </p>

                    <select
                      className="form-control custom-select"
                      onChange={(e) => setVideoImage(e.target.value)}
                      value={videoImage}
                    >
                      <option>Select</option>
                      {_.map(videoImages, (vit) => (
                        <option value={vit.value}>{vit.name}</option>
                      ))}
                    </select>
                  </div>
                </Fragment>
              )}
              {videoImage ? (
                <div className="bottom-video">
                  <div className="webcam-close-icon col-img">
                    {videoUrl ? (
                      <ReactPlayer
                        url={`${videoUrl}`}
                        controls
                        playing
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Fragment>
          )}

          {isNdaSigned && (
            <div className="nda-btn-wrapper">
              <div className="nda-heading">
                To initiate this survey please sign "Non Disclosure Agreement"
              </div>
              <Link className="login-txt" to={`#`} onClick={handleRedirection}>
                Let me sign it
              </Link>
            </div>
          )}
        </div>
      </div>
    </SocketProvider.Provider>
  );
};
export default SurveyScreener;
