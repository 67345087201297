import React, { useMemo, useState } from "react";
import Speech from "speak-tts";
import { Navbar, Nav } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../images/logo.png";
import { Input, Button } from "reactstrap";
import "./home.css";
import _ from "lodash";
import ReactPlayer from "react-player";
const SurveyLanding = ({ history }) => {
  const [surveyId, setSurveyId] = useState("");
  const [error, setError] = useState(false);
  const speech = new Speech();
  const [refresh, setRefrsh] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(surveyId);
    if (!_.isNull(surveyId) && surveyId) {
      window.open(`/surveys/${surveyId}`, "_blank");
    } else {
      setError(true);
    }
  };
  const handleChange = (e) => {
    setSurveyId(e.target.value);
    setError(false);
  };
  useMemo(() => {
    if (refresh) {
      speech
        .speak({
          text: "Please Enter Survey ID",
        })
        .then(() => {
          setRefrsh(true);
          console.log("Success !");
        })
        .catch((e) => {
          setRefrsh(true);
          console.error("An error occurred :", e);
        });
    }
  }, [refresh]);

  return (
    <div className="wrapper">
      <Navbar
        class="shadow p-3 mb-5 bg-white rounded"
        style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
      >
        <div class="container-fluid">
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
        </div>
      </Navbar>

      <div className="home">
        <div className="container">
          <div className="row mb-5 pb-5">
            <div className="col-md-12">
              <div className="welcome text-center">
                <h4>
                  <b>Enter Survey ID</b>
                </h4>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="col-md-6"
                      style={{
                        width: "400px",
                        "margin-top": "10px",
                        "margin-left": "360px",
                        "margin-bottom": "10px",
                      }}
                    >
                      <Input
                        name="surveyId"
                        id="surveyId"
                        onChange={handleChange}
                      />
                      {error ? (
                        <p className="text-danger t5">Please Enter Survey ID</p>
                      ) : null}
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
                <Button
                  class="btn btn-primary btn-lg"
                  role="button"
                  onClick={handleSubmit}
                  style={{ "background-color": "rgb(10, 56, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            {/* <ReactPlayer
              playing={true}
              url="https://kiaraone.dealopia.com/speechtraining.mp4"
              controls={true}
              playing={true}
              muted={false}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyLanding;
