import React, { useMemo, useState } from "react";
import Speech from "speak-tts";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import logo from "../images/logo.png";
import banner from "../images/banner.png";
import webcam from "../images/webcam.png";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import ControlledLottie from "../lotties/ControlledLottie";

const Landing = () => {
  const speech = new Speech();
  const [refresh, setRefrsh] = useState(true);

  useMemo(() => {
    if (refresh) {
      speech
        .speak({
          text: "Welcom To Nelson Recruiting",
        })
        .then(() => {
          setRefrsh(true);
          console.log("Success !");
        })
        .catch((e) => {
          setRefrsh(true);
          console.error("An error occurred :", e);
        });
    }
  }, [refresh]);

  return (
    <div className="wrapper">
      <Navbar
        class="shadow p-3 mb-5 bg-white rounded"
        style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
      >
        <div class="container-fluid">
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <a class="btn btn-primary btn-md" href="/backend" role="button">
                Login
              </a>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className="home">
        <div class="right-img-container">
          <div class="webcam-close-icon col-img text-center">
            <Image src={webcam} style={{ width: 100 }} />
            <p style={{ lineHeight: 1 }}>
              {" "}
              Camera Integrated
              <br />{" "}
            </p>
          </div>
          <div class="animated-img col-img">
            {" "}
            {/*<Image style={{ height:100, }} src={'https://www.geniuscr8.com/wp-content/uploads/2018/07/Genius-Concept-Sample-Logo-2.jpg'} />*/}{" "}
            <ControlledLottie />{" "}
          </div>
        </div>
        <div className="container">
          <div className="row mb-5 pb-5">
            <div className="col-md-12">
              <div className="welcome text-center">
                <h3 class="display-3">Nelson Recruiting</h3>
                <p>
                  To get started, you must first, read and accept{" "}
                  <span className="item-blue font-weight-bold">NDA</span>
                </p>
                <a class="btn btn-primary btn-lg" href="/backend" role="button">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-4 item">
              <FontAwesomeIcon icon={faFacebook} color="white" size="5x" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at
                augue et nulla rutrum pulvinar et ac nunc. Orci varius natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus.
              </p>
            </div>
            <div className="col-md-4 item">
              <FontAwesomeIcon icon={faTwitter} color="white" size="5x" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at
                augue et nulla rutrum pulvinar et ac nunc. Orci varius natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus.
              </p>
            </div>
            <div className="col-md-4 item">
              <FontAwesomeIcon icon={faInstagram} color="white" size="5x" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at
                augue et nulla rutrum pulvinar et ac nunc. Orci varius natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Image className="img-fluid mb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
