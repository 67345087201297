import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import "../index.css";
import logo from "../images/logo.png";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../../node_modules/startbootstrap-simple-sidebar/css/simple-sidebar.css";
const TakeSurvey = (props) => {
  const [isRefresh, setIsRefresh] = useState(false);

  // const handleRedirection = (e) => {
  //   e.preventDefault();
  //   if (localStorage.getItem("surveyId")) {
  //     let surveyIdNo = localStorage.getItem("surveyId");
  //     let queryDatas = props.history.location.search;
  //     if (surveyIdNo && queryDatas) {
  //       props.history.push(`/surveys/${surveyIdNo + queryDatas}`);
  //       console.log(surveyIdNo + queryDatas);
  //     }

  //   }
  // };
  return (
    <Fragment>
      <Navbar
        className="shadow bg-white rounded"
        style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
      >
        <div className="container-fluid">
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"></Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className="container body-wrapper">
        <div className="nda-btn-wrapper">
          <div class="nda-heading d-none">
            To initiate this survey please sign "Non Disclosure Agreement"
          </div>
          <h4>Thank You</h4>
          {/* <Link
            className="login-txt btn-lg btn-block"
            style={{ "min-width": "331px" }}
            to={`#`}
          >
            
          </Link> */}
        </div>
      </div>
    </Fragment>
  );
};

export default TakeSurvey;
